import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { IoIosCall, IoIosMail, IoIosPin } from 'react-icons/io'
import { Container, Row, Col, Card } from 'reactstrap'
import Helmet from 'react-helmet'
import ImageTeam from '../components/imageTeam'
import Layout from '../components/layout'
import logo from '../images/austriaguides.png'

const ContactPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet
      title="Contact our Salzkammergut tour guides"
      meta={[
        { name: 'description', content: 'Austria Guides are trained and state-certified tour guides' },
        { name: 'keywords', content: 'Tours, Guides, Salzkammergut, Hallstatt, Bad Aussee, St. Wolfgang, Gmunden, Bad Goisern, Salzburg, Linz, Austria Guides' },
      ]} />
    <ImageTeam />

    <Container className="py-0 py-lg-4">
      <Row className="my-5">
        <Col lg="7">
          <h6 className="text-uppercase">Your Austria Guides</h6>
          <h1 className="mb-3 text-uppercase">In the Salzkammergut</h1>
          <p className="text-justify">
            We strive to develop entertaining and informative tours for our guests. Depending on availability, the best guide for your particular needs will be selected.
          </p>
        </Col>
        <Col lg={{ size: 4, offset: 1 }} className="mt-4 mt-lg-0">
          <h6 className="text-uppercase">Contact</h6>
          <p>You are welcome to contact us.</p>
          {data.contact.edges.map(({ node }, idx) => (
            <div key={`team-contact-${idx}`}>
              <p><IoIosCall className="mr-2 h4 mb-0" /> <a href={`tel:${node.frontmatter.phone}`}>{node.frontmatter.phone}</a></p>
              <p><IoIosMail className="mr-2 h4 mb-0" /> <a href={`mailto:${node.frontmatter.email}`}>{node.frontmatter.email}</a></p>
            </div>
          ))}
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="py-4 rounded text-center border-top border-bottom">
            <Row className="justify-content-center"><Col xs="6" md="2"><img className="img-fluid" src={logo} alt="Austria Guides" /></Col></Row>
            <Row className="justify-content-center"><Col md="7"><h3 className="mt-4 mt-lg-5">We are a team of state-certified tour guides.</h3></Col></Row>
          </div>
        </Col>
      </Row>
    </Container>

    <Container className="container mt-5 py-5">
      <Row>
        {data.team.edges.map(({ node }, idx) => (
          <Col lg="4" md="6" className="mb-5" key={`team-${idx}`}>
            <Card className="border-0">
              <Img fluid={node.frontmatter.image.childImageSharp.fluid} className="card-img-top rounded-top" alt={`${node.frontmatter.firstName} ${node.frontmatter.lastName}`} />
              <div className="card-body">
                <h5 className="card-title">{node.frontmatter.firstName} {node.frontmatter.lastName}</h5>
                <p className="card-text">{node.frontmatter.languages_en}</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <IoIosCall className="mr-3 h4 mb-0" />
                  <a href={`tel:${node.frontmatter.phone}`}>{node.frontmatter.phone}</a>
                </li>
                <li className="list-group-item">
                  <IoIosMail className="mr-3 h4 mb-0" />
                  <a href={`mailto:${node.frontmatter.email}`}>{node.frontmatter.email}</a>
                </li>
              </ul>
              <div className="card-body">
                <IoIosPin className="mr-3 h4 mb mt-3 float-left" />
                <address>{node.frontmatter.address_street}<br />{node.frontmatter.address_zip} {node.frontmatter.address_city}</address>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  </Layout>
)

export const query = graphql`
query {
  contact: allMarkdownRemark(filter: { 
    fileAbsolutePath: {regex : "/src\/team/"}
    frontmatter: {
      lastName: { eq: "Campanile" }
    }
  }
  limit: 1) {
    edges {
      node {
        id
        frontmatter {
          phone
          email
        }
      }
    }
	}
  team: allMarkdownRemark(
    filter: { fileAbsolutePath: {regex : "/src\/team/"}}
    sort: { fields:frontmatter___lastName }
  ) {
    edges {
      node {
        frontmatter {
          firstName
          lastName
          phone
          email
          address_street
          address_zip
          address_city
          languages_en
          image {
            childImageSharp {
              fluid(maxWidth: 510, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`

export default ContactPage;